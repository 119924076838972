import './App.css';
import { TextField } from '@mui/material';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          twinkle
        <div> <TextField variant="outlined" label="Username" /></div>
        <div> <TextField variant="outlined" label="Email" type="email" /></div>
          <div> <TextField variant="filled" label="Password" type="password" /></div>
        </div>
      </header>
    </div>
  );
}

export default App;
